import React, {useEffect, useRef, useReducer} from "react";
import {Link, useParams} from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import AllocationList from "../../components/AllocationList/AllocationList";
import fetchModel from "../../models/fetchModel";
import dateModel from "../../models/dateModel";

import './_Allocations.scss';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const initialState = {
  event: null,
  isLoading: false,
  errorMessage: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_START':
      return { ...state, isLoading: true };
    case 'FETCH_SUCCESS':
      return { ...state, isLoading: false, event: action.payload, errorMessage: '' };
    case 'FETCH_ERROR':
      return { ...state, isLoading: false, errorMessage: action.payload };
    default:
      return state;
  }
}

function Allocations({userRole}) {
  const params = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { event, isLoading, errorMessage } = state;
  const eventId = params.id;
  const prevEventId = usePrevious(eventId);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current && eventId !== prevEventId && !isLoading) {
      isFirstRender.current = false;
      dispatch({ type: 'FETCH_START' });
      const fetchFunction = userRole === 'staff' ? fetchModel.postStaff : fetchModel.post;
      const fetchUrl = userRole === 'staff' ? '/staff/event-list': '/event-list';
      fetchFunction(fetchUrl, {eventId}, (response) => {
        if (response.error) {
          dispatch({ type: 'FETCH_ERROR', payload: response.error.message });
        } else {
          dispatch({ type: 'FETCH_SUCCESS', payload: response[0] });
        }
      });
    }
  }, [eventId, userRole, isLoading, prevEventId]);

  const handleDownloadCSV = () => {
    if (event) {
      const downloadUrl = `${process.env.REACT_APP_WEB_URL}/data/export/${event.id}`;
      window.open(downloadUrl, '_blank');
    }
  };

  return (
    <main className="Allocations">
      <h1>Allocations{event?.name ? ` for ${event.name}` : ''}</h1>
      {errorMessage && <p>{errorMessage}</p>}
      <div className="Allocations-options">
        <Link to={`${process.env.REACT_APP_PUBLIC_URL}/allocations`} className="Button">Back to all events</Link>
        {event && userRole === 'organiser' && (
          <button onClick={handleDownloadCSV} className="Button">
            Download CSV
          </button>
        )}
      </div>
      {isLoading ? (
        <div className="Allocations-loading">
          <Loading data-testid="loading" />
        </div>
      ) : event ? (
        <>
          <div className="Allocations-event-row">
            <div className="Allocations-event">
              <h2 className="Allocations-event-name">
                <Link to={`/allocations/${event.id}`} className="Allocations-event-name-link">
                  {event.name}
                </Link>
              </h2>
              <p>{event.subtitle}</p>
              <p>{dateModel.formatFromDb(event.date)}</p>
              <p>{event.currency_symbol}{event.price}</p>
              <p>{event.sold} sold</p>
            </div>
          </div>
          <AllocationList allocations={event.tickets} userRole={userRole} />
        </>
      ) : (
        <p>No event found</p>
      )}
    </main>
  );
}

export default Allocations;
