import fetchModel from "../../models/fetchModel";
import Loading from "../Loading/Loading";
import React, {useEffect, useState} from "react";

import './_AdmitToggle.scss';

const AdmitToggle = ({ ticketId, admitted, callback, userRole }) => {
  let [isLoading, setIsLoading] = useState(false);
  let [mutableAdmitted, setMutableAdmitted] = useState(admitted);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setMutableAdmitted(admitted)
  },[admitted])

  function toggleAdmitted(e) {
    setIsLoading(true);
    const newAdmitted = !mutableAdmitted;

    const responseCallback = (response) => {
      if (response) {
        setIsLoading(false);
        if (response.error) {
          setErrorMessage(response.error.message);
          return;
        }

        if (response.admitted === true) {
          setMutableAdmitted(true);
        } else if (response.admitted === false) {
          setMutableAdmitted(false);
        }
        callback(response.admitted);
      }
    }

    let url = userRole === 'staff' ? '/staff/ticket/admission' : '/ticket/admission';
    const fetchFunction = userRole === 'staff' ? fetchModel.postStaff : fetchModel.post;

    fetchFunction(
      url,
      { ticketId, admitted: newAdmitted },
      responseCallback
    );
  }

  function errorMessages() {
    return isLoading || !errorMessage ? null : <div className="AdmitToggle-error">{errorMessage}</div>
  }

  function loadingOrNot() {
    return (
      <>
        <label 
          className={`AdmitToggle-label ${mutableAdmitted ? 'isAdmitted' : ''}`}
          data-testid="admit-toggle"
          onClick={() => toggleAdmitted()}
        >
          {isLoading ? <Loading /> : null}
          <input
            className="AdmitToggle-checkbox"
            type="checkbox"
            // checked={mutableAdmitted}
            onChange={toggleAdmitted}
          />
          <span className="AdmitToggle-text">{mutableAdmitted ? 'Guest Admitted' : 'Admit Guest'}</span>
        </label>
      </>
    )
  }
  
  return <div className="AdmitToggle">
    {loadingOrNot()}
    {errorMessages()}
  </div>;
};

export default AdmitToggle;
