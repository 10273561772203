
import React, {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import AllocationList from "../../components/AllocationList/AllocationList";
import fetchModel from "../../models/fetchModel";

import './_Events.scss';
import dateModel from "../../models/dateModel";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";

function useFirstRender() {
  const ref = useRef(true);
  const firstRender = ref.current;
  ref.current = false;
  return firstRender;
}

let Events = function({userRole}) {
  let params = useParams()
  let [events, setEvents] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  let [isUpcoming, setIsUpcoming] = useState(true);
  let [showAttendees, setShowAttendees] = useState(false);
  let firstRender = useFirstRender();
  let [attendees, setAttendees] = useState([]);
  let [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getAllocations();
    // eslint-disable-next-line
  }, [isUpcoming]);

  // Get /allocations
  const getAllocations = function() {
    const postBody = {};

    if (isLoading && !firstRender) {
      return;
    }

    setIsLoading(true);

    if (!isUpcoming) {
      postBody.isUpcoming = false;
    } else {
      postBody.isUpcoming = true;
    }

    const responseCallback = (response) => {
      if (!response.error) {
        setEvents(response);
        setIsLoading(false);

        let newAttendees = []
        response.forEach((thisEvent) => {
          thisEvent.tickets.forEach((thisTicket) => {
            thisTicket.eventName = thisEvent.name;
            newAttendees.push(thisTicket);
          });
        })

        setAttendees(newAttendees);
      } else {
        setErrorMessage(response.error.message);
        setIsLoading(false);
      }
    }

    if (userRole === 'staff') {
      const url = "/staff/event-list";
      fetchModel.postStaff(url, postBody, responseCallback);
    } else {
      const url = "/event-list";
      fetchModel.post(url, postBody, responseCallback);
    }
  }

  function updateShowAttendees() {
    setShowAttendees(true)
  }

  function updateDontShowAttendees() {
    setShowAttendees(false)
  }

  function eventsTemplate() {
    if (isLoading || firstRender) {
      return <div className="Allocations-loading">
        <Loading />
      </div>
    }

    if (events.length === 0) {
      return <p>No events found</p>;
    }

    if (events.length > 0) {
      return (
        <>
          <ButtonGroup buttons={[
            {
              'text': 'Events',
              'isActive': !showAttendees,
              'callback': updateDontShowAttendees
            },
            {
              'text': 'Attendees',
              'isActive': showAttendees,
              'callback': updateShowAttendees
            },

          ]}></ButtonGroup>

          {!showAttendees ? (
            <>
              {events.map((thisEvent, index) => (
                <div
                  key={index}
                  className="Allocations-event-row"
                >
                  <div className="Allocations-event">
                    <h2 className="Allocations-event-name">
                      <Link
                        to={`${process.env.REACT_APP_PUBLIC_URL}/allocations/${thisEvent.id}`}
                        className="Allocations-event-name-link">
                        {thisEvent.name}
                      </Link>
                    </h2>
                    <p>{thisEvent.subtitle}</p>
                    <p>{dateModel.formatFromDb(thisEvent.date)}</p>
                    <p>{thisEvent.currency_symbol}{thisEvent.price}</p>
                    <p>{thisEvent.sold} sold</p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <AllocationList allocations={attendees} userRole={userRole}></AllocationList>
          )}
        </>
      )
    }
  }

  function updateIsUpcoming() {
    setIsUpcoming(true)
  }

  function updateIsNotUpcoming() {
    setIsUpcoming(false)
  }

  return (
    <main className="Allocations">
      <h1>Event list</h1>
      <div className="Allocations-eventList">
        {errorMessage && <p>{errorMessage}</p>}
        {!params.id ? (
            <ButtonGroup buttons={[
              {
                'text': 'Today/Upcoming',
                'callback': updateIsUpcoming,
                isActive: isUpcoming
              },
              {
                'text': 'Past',
                'callback': updateIsNotUpcoming,
                isActive: !isUpcoming
              },

            ]}></ButtonGroup>
          ) : (
            <Link to="/allocations" className="Button">Back to all events</Link>
          )
        }
        {eventsTemplate()}
      </div>
    </main>
  );
}

export default Events;
