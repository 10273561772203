import React, {useEffect, useState} from "react";
import './_TicketInfo.scss';
import dateModel from "../../models/dateModel";
import AdmitToggle from "../AdmitToggle/AdmitToggle";

function TicketInfo ({ticket, close, userRole}) {
  const [mutableTicket, setMutableTicket] = useState(ticket);
  const [admitted, setAdmitted] = useState(ticket.admitted);

  useEffect(() => {
    setMutableTicket(ticket);
    setAdmitted(ticket.admitted);
  }, [ticket]);

  ticket.timestamp2 = dateModel.formatFromDb(ticket.timestamp);

  return <div className="TicketInfo">
    <button className="TicketInfo-close" onClick={close}>Close</button>
    <h2 className="TicketInfo-name">{mutableTicket.name}</h2>
    <p className="TicketInfo-code">{mutableTicket.code}</p>
    <p className="TicketInfo-eventName">{mutableTicket.eventName}</p>
    <p className="TicketInfo-quantity">{mutableTicket.quantity} x tickets</p>
    <div className="TicketInfo-admitted">
      <AdmitToggle
        className="TicketInfo-admitToggle"
        admitted={admitted}
        ticketId={mutableTicket.id}
        userRole={userRole} />
    </div>
    <ul className="TicketInfo-customFields">
      {mutableTicket.custom_field_values && mutableTicket.custom_field_values.map((customField, index) => (
        <li
          className="TicketInfo-customField"
          key={index}>
          <p className="TicketInfo-customFieldName">{customField.name}</p>
          <p className="TicketInfo-customFieldValue">{customField.value}</p>
        </li>
      ))}
    </ul>
    <p className="TicketInfo-timestamp">Purchase date/time: {mutableTicket.timestamp2}</p>
  </div>;
}

export default TicketInfo;
