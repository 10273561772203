
const dateModel = {
  formatFromDb: (dateString) => {
    if (dateString === '0000-00-00 00:00:00') {
      return dateString;
    }

    const date = new Date(dateString + 'Z'); // Append 'Z' to treat as UTC
    
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
}

export default dateModel;
