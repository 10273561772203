import React, {useCallback, useEffect, useState} from "react";
import './_AllocationList.scss';
import TicketInfo from "../TicketInfo/TicketInfo";
import AdmitToggle from "../AdmitToggle/AdmitToggle";
import Filter from "../Filter/Filter";
import ButtonGroup from "../ButtonGroup/ButtonGroup";

function AllocationList(props) {
  const [filterString, setFilterString] = useState('')
  const [allocations, setAllocations] = useState(props.allocations);
  const [filteredAllocations, setFilteredAllocations] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [infoSubject, setInfoSubject] = useState({});
  const [isAdmitted, setIsAdmitted] = useState(null)

  useEffect(() => {
    const allocations = props.allocations;
    // each event has a list of tickets, so add them all to one list with the name of the event
    setAllocations(allocations);
    setFilteredAllocations(allocations?.flat());
  }, [props.allocations]);

  const filterByAdmitted = useCallback((ticketAdmittedState) => {
    switch (isAdmitted) {
      case true:
        return ticketAdmittedState === true;
      case false:
        return ticketAdmittedState === false;
      default:
        return true;
    }
  }, [isAdmitted]);

  useEffect(() => {
    // filter by name or code
    setFilteredAllocations(
      allocations?.filter(ticket => {
        let nameFilter = [];
        let codeFilter = [];
        // check that the ticket name or code is a string
        if (typeof ticket.name === 'string') {
          nameFilter = ticket.name.toLowerCase().includes(filterString.toLowerCase());
        }

        if(typeof ticket.code === 'string') {
          codeFilter = ticket.code.toLowerCase().includes(filterString.toLowerCase());
        }

        const admittedFilter = filterByAdmitted(ticket.admitted)

        return (nameFilter || codeFilter) && admittedFilter;
      })
    );
  }, [filterString, allocations, isAdmitted, filterByAdmitted]);

  function showAdmitted(){
    setIsAdmitted(true)
  }

  function showUnadmitted(){
    setIsAdmitted(false)
  }

  function showAll() {
    setIsAdmitted(null)
  }

  return <div className="AllocationList">
    <div
      className="AllocationList-filters"
    >
      <Filter onChange={setFilterString}></Filter>
      <ButtonGroup buttons={[
        {
          'text': 'All',
          'isActive': isAdmitted === null,
          'callback': showAll
        },
        {
          'text': 'Admitted',
          'isActive': isAdmitted,
          'callback': showAdmitted
        },
        {
          'text': 'Unadmitted',
          'isActive': isAdmitted === false,
          'callback': showUnadmitted
        }
      ]}></ButtonGroup>
    </div>
    <ul className="AllocationList-list">
      {filteredAllocations?.map((ticket, index) => (
        <li
          className="AllocationList-listItem"
          key={index}>
          <h3 className="AllocationList-name">{ticket.name}</h3>
          {ticket.eventName && <p className="AllocationList-eventName">{ticket.eventName}</p>}
          <p className="AllocationList-code">{ticket.code}</p>
          <button className="AllocationList-infoButton" onClick={() => {
            setShowInfo(true);
            setInfoSubject(ticket);
          }}>Info</button>
          <div
            className="AllocationList-admitToggle">
            <AdmitToggle
              ticketId={ticket.id}
              admitted={ticket.admitted}
              callback={(newValue) => {
                // update the ticket in the list with the new admitted value
                const newAllocations = [...allocations];
                const ticketIndex = newAllocations.findIndex(t => t.id === ticket.id);
                newAllocations[ticketIndex].admitted = newValue;
                setAllocations(newAllocations);
              }}
              userRole={props.userRole}
            />
          </div>
        </li>
      ))}
      {showInfo && <TicketInfo ticket={infoSubject} close={() => {
        setShowInfo(false);
        setInfoSubject({});
      }} />}
    </ul>
  </div>
}

export default AllocationList;
