import {Route, Routes} from 'react-router-dom';
import Allocations from './pages/Allocations/Allocations.js';
import Events from './pages/Events/Events.js';
import Staff from './pages/Staff/Staff.js';
import Scan from './pages/Scan/Scan.js';
import Login from './pages/Login/Login.js';
import NavBar from './components/NavBar/NavBar.js';
import NotFound from './pages/NotFound/NotFound.tsx';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary.tsx';
import React, {useState} from "react";

import logo from './ticketlab.svg';
import './App.scss';
import cookieModel from "./models/cookieModel";

const App = function () {
  const staffAccessToken = cookieModel.getCookie('ticketlab_staff_access');
  const accessToken = cookieModel.getCookie('ticketlab_access');
  let [isLoggedIn, setIsLoggedIn] = useState(staffAccessToken || accessToken);
  let [userRole, setUserRole] = useState(accessToken ? 'organiser' : 'staff');

  const loginElement = <Login setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} />;

  const baseUrl = process.env.REACT_APP_PUBLIC_URL || '';

  let routeArray = [
    {
      path: baseUrl + '/',
      element: <Events userRole={userRole} />,
      authGuard: true,
      inNav: false
    },
    {
      path: baseUrl + '/login',
      element: loginElement,
      authGuard: false,
      inNav: false
    },
    {
      path: baseUrl + '/allocations',
      element: <Events userRole={userRole} />,
      name: 'Events',
      authGuard: true,
      inNav: true
    },
    {
      path: baseUrl + '/allocations/:id',
      element: <Allocations userRole={userRole} />,
      name: 'Allocations/Event',
      authGuard: true,
      inNav: false
    },
    {
      path: baseUrl + '/staff',
      element: <Staff />,
      name: 'Staff',
      authGuard: true,
      role: 'organiser',
      inNav: true
    },
    {
      path: baseUrl + '/scan',
      element: <Scan userRole={userRole} />,
      name: 'Scan',
      authGuard: true,
      inNav: true
    },
    {
      path: '*',
      element: <NotFound />,
      name: 'Not Found',
      authGuard: false,
      inNav: false
    }
  ];

  const getRoutes = () => {
    return (
      <Routes>
        {routeArray.map((route, index) => {
          if (route.path === '*') {
            return <Route key={index} path={route.path} element={route.element} />;
          }
          return (
            <Route
              key={index}
              path={route.path}
              element={route.authGuard && !isLoggedIn ? loginElement : route.element}
            />
          )
        })}
      </Routes>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Gofamer"/>
        <NavBar routes={routeArray} isLoggedIn={isLoggedIn} userRole={userRole} ></NavBar>
      </header>
      <div className="App-body">
        <ErrorBoundary fallback={<p>Something went wrong</p>}>
          {getRoutes()}
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default App;
