import React, {useCallback, useEffect, useState} from "react";
import {QrReader} from 'react-qr-reader';
import './_Scan.scss';
import fetchModel from "../../models/fetchModel";
import TicketInfo from "../../components/TicketInfo/TicketInfo";

const Scan = ({userRole}) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState('');
  const [ticketInfo, setTicketInfo] = useState({});
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    if (code !== '') {
      closeCamera();
    }

  }, [code]);

  async function closeCamera() {
     const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: true,
    });
    if (stream && typeof stream.getTracks === 'function') {
      stream.getTracks().forEach(function (track) {
          track.stop();
          track.enabled = false;
      });
    }
    // ref.current.stopCamera()
  }

  const responseCallback = (response) => {
    if (response) {
      if (response.error) {
        setError(response.error);
        return;
      }
      setTicketInfo(response)
      setShowInfo(true);
    } else {
      console.error('error', response.error);
    }
    setIsLoading(false);
  }

  const getTicket = useCallback((thisCode) => {
    setIsLoading(true)
    if (userRole === 'staff') {
      fetchModel.postStaff(`/staff/ticket/lookup`, {
        code: thisCode
      }, responseCallback);
    } else if (userRole === 'organiser') {
      fetchModel.post(`/ticket/lookup`, {
        code: thisCode
      }, responseCallback);
    }
  }, [userRole]) 

  const handleQRRequest = useCallback((result, error) => {
    if (error) {
      // console.error('QR Scan Error:', error);
      // setError(error.message || 'An error occurred while scanning');
      // return;
    }
    
    if (result) {
      const oldCode = code;
      if (result.text.includes('https://ticketlab.co.uk/ticket/')) {
        const newCode = result.text.split('/').pop();
        setCode(newCode);
        setError(null); // Clear any previous errors
        
        if (newCode !== oldCode && !isLoading) {
          getTicket(newCode)
        }
      } else {
        setError('Invalid QR code format');
      }
    }
  }, [code, isLoading, getTicket]);

  return (
    <main className="Scan">
      <h1>Scan</h1>
      <form
        className="Scan-form"
        onSubmit={e => {
          e.preventDefault();
          setIsLoading(true);
          getTicket(code);
        }}
      >
        <label>Enter code:
          <input
            className="Input-field"
            type="text"
            placeholder="Enter ticket code"
            value={code}
            onChange={e => setCode(e.target.value)}
          />
        </label>
        <button className="Scan-submit Button">Submit</button>
      </form>
      
      <p className="Error">{error}</p>
      <QrReader
        onResult={handleQRRequest}
        style={{ width: '100%' }}
        constraints={{ facingMode: 'environment' }}/>
      {ticketInfo.ticket && showInfo &&
        <TicketInfo ticket={ticketInfo.ticket} userRole={userRole} close={() => {
          setShowInfo(false);
          setTicketInfo({});
        }} />
      }
    </main>
  );
}

export default Scan;
